<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Delivery Settings</h1>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h2>Costs</h2>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed color="accent" @click="$refs.costDialog.open()"
              ><v-icon left>mdi-plus</v-icon>Add New Cost</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="costs"
        no-data-text="No Delivery Costs Found"
      >
        <template v-slot:item.cost_from="{ item }">
          £{{ item.cost_from }}
        </template>
        <template v-slot:item.cost_to="{ item }">
          <span v-if="item.cost_to">£{{ item.cost_to }}</span>
        </template>
        <template v-slot:item.price="{ item }">£{{ item.price }}</template>
        <template v-slot:item.includes_vat="{ item }">
          {{ item.includes_vat ? "True" : "False" }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.costDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <cost-dialog ref="costDialog" />
  </div>
</template>

<script>
import CostDialog from "./components/CostDialog.vue";

export default {
  components: {
    CostDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Ecommerce",
          disabled: true,
        },
        {
          text: "Delivery Settings",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Cost From", value: "cost_from" },
        { text: "Cost To", value: "cost_to" },
        { text: "Price", value: "price" },
        { text: "Includes VAT?", value: "includes_vat" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    costs() {
      return this.$store.getters["ecommerce/deliverySettingsStore/allCosts"];
    },
  },
};
</script>
