<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Delivery Cost
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="cost-form">
          <v-text-field
            label="Cost From *"
            v-model="fields.cost_from"
            type="number"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('cost_from')"
            :error-messages="errors['cost_from']"
          ></v-text-field>
          <v-text-field
            label="Cost To"
            v-model="fields.cost_to"
            type="number"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('cost_to')"
            :error-messages="errors['cost_to']"
          ></v-text-field>
          <v-text-field
            label="Price *"
            v-model="fields.price"
            type="number"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>
          <v-switch
            label="Price Includes VAT?"
            v-model="fields.includes_vat"
            inset
            color="green"
            :error="errors.hasOwnProperty('includes_vat')"
            :error-messages="errors['includes_vat']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="cost-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      cost: {},
      fields: {
        cost_from: null,
        cost_to: null,
        price: null,
        includes_vat: false,
      },
      errors: {},
    };
  },

  methods: {
    open(cost = null) {
      if (cost !== null) {
        this.cost = cost;
        this.isEditing = true;
        this.fields.cost_from = cost.cost_from;
        this.fields.cost_to = cost.cost_to;
        this.fields.price = cost.price;
        this.fields.includes_vat = cost.includes_vat;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.cost = {};
      this.fields.cost_from = null;
      this.fields.cost_to = null;
      this.fields.price = null;
      this.fields.includes_vat = false;
      this.errors = {};
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.costId = this.cost.id;
      }

      this.$store
        .dispatch("ecommerce/deliverySettingsStore/saveCost", payload)
        .then(() => this.reset())
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
